import axios from "axios";
import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";

const ContactUs = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form before submitting

    // Check if the name is not empty
    if (formData.name.trim() === "") {
      alert("Please enter your name.");
      return;
    }

    // Validate email format before submitting the form
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Check if the message is not empty
    if (formData.message.trim() === "") {
      alert("Please enter your message.");
      return;
    }

    // If all validations pass, you can proceed with submitting the form
    console.log("Form data:", formData);

    try {
      console.log(formData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/store/contact-us/`,
        {
          ...formData,
        }
      );
      if (response.status === 201) {
        // Reset the form after submission
        setFormData(initialFormData);
        setFormSubmitted(true);
      }
    } catch (error) {
      console.log(error);
    }

    // Hide the success message after 3 seconds
    setTimeout(() => {
      setFormSubmitted(false);
    }, 3000);
  };

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className="container">
        <div className="flex flex-col gap-4 md:flex-row">
          {/* Left Section */}
          <div className="md:w-1/2 py-4">
            <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
            <p>
              Feel free to reach out to us for any inquiries or feedback. We'd
              love to hear from you. Thank you
            </p>
            <div className="mt-8">
              <h2 className="text-xl font-bold mb-2">Contact Information</h2>
              <p>Email: lifelineachham2080.com</p>
              <p>
                Phone: Mahendra Kunwar:9848444014,
                <br />
                Dr.Krishna Bahadur Sodari:9843757325
                <br />Arbin Kunwar: 9742387566
              </p>
              <p>Address: Sanfebagar, Achham</p>
              {/* Add other contact information as needed */}
            </div>
            <div className="mt-8">
              <h2 className="text-xl font-bold mb-2">Follow Us</h2>
              <div className="flex">
                <a href="#" className="mr-4">
                  <FaFacebook className="text-blue-500 text-2xl" />
                </a>
                <a href="#" className="mr-4">
                  <FaTiktok className="text-blue-400 text-2xl" />
                </a>
                <a href="#" className="mr-4">
                  <FaInstagram className="text-pink-500 text-2xl" />
                </a>
                {/* Add other social media links with appropriate React Icons */}
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="md:w-1/2 bg-white py-6 px-8 rounded-md">
            <h2 className="text-2xl font-bold mb-4">Contact Form</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="border border-gray-400 rounded px-3 py-2 w-full"
                  placeholder="Your Name"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="border border-gray-400 rounded px-3 py-2 w-full"
                  placeholder="Your Email"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="phone"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="border border-gray-400 rounded px-3 py-2 w-full"
                  placeholder="Your phone number"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  className="border border-gray-400 rounded px-3 py-2 w-full"
                  placeholder="Your Message"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
              {formSubmitted && (
                <p className="text-green-500 mt-2">
                  Form submitted successfully!
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
