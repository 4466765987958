import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBBtn,
  MDBCheckbox,
  MDBContainer,
  MDBInput,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import React, { useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser, registerUser } from "../features/user/userSlice";
import { updateNotification } from "../features/notification/notificationSlice";

function LoginSignup() {
  const navigate = useNavigate();

  const { first_name } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const userCookie = new Cookies();

  const [loading, setLoading] = useState(false);

  const [givenEmail, setGivenEmail] = useState("");
  const [givenPassword, setGivenPassword] = useState("");
  const [password, setPassword] = useState("");
  const [givenUsername, setGivenUsername] = useState("");
  const [givenName, setGivenName] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    cpassword: false,
    login_pass: false,
  });

  const [justifyActive, setJustifyActive] = useState("tab1");

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  const handleRegister = async () => {
    setLoading(true);
    const name = givenName.split(" ");
    if (
      givenEmail === "" ||
      password === "" ||
      givenUsername === "" ||
      name[0] === "" ||
      name[1] === ""
    ) {
      return;
    }

    try {
      const result = await dispatch(
        registerUser({
          username: givenUsername,
          email: givenEmail,
          password: password,
          first_name: name[0],
          last_name: name[1],
        })
      );

      if (result.payload?.success) {
        setPassword("");
        setGivenUsername("");
        setGivenName("");
        handleJustifyClick("tab1");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred during registration:", error);
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    const actionResult = await dispatch(
      loginUser({ email: givenEmail, password: givenPassword })
    );

    setLoading(false);
    if (actionResult && actionResult.payload && actionResult.payload.access) {
      dispatch(
        updateNotification({
          name: "message",
          value: "Logged in successfully",
        })
      );
      dispatch(
        updateNotification({
          name: "showModal",
          value: true,
        })
      );
      navigate("/");
    }
  };

  if (userCookie.get("access")) {
    navigate("/");
    return;
  }

  if (first_name) {
    return (
      <div className="text-center">
        <button className="m-4 w-50" onClick={() => navigate("/")}>
          Home
        </button>
      </div>
    );
  }

  return (
    <div className="py-8 sm:py-12 md:py-16 bg-gray-100">
      <div className="container">
        <MDBContainer fluid className="max-w-[600px] mx-auto">
          <MDBTabs
            pills
            justify
            className="mb-3 d-flex flex-row justify-content-between"
          >
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleJustifyClick("tab1")}
                active={justifyActive === "tab1"}
                style={{
                  color: justifyActive === "tab1" ? "white" : "#414242",
                  fontWeight: "bold",
                  backgroundColor:
                    justifyActive === "tab1" ? "#709dff" : "#d4e2ff",
                }}
              >
                Login
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleJustifyClick("tab2")}
                active={justifyActive === "tab2"}
                style={{
                  color: justifyActive === "tab2" ? "white" : "#414242",
                  fontWeight: "bold",
                  backgroundColor:
                    justifyActive === "tab2" ? "#709dff" : "#d4e2ff",
                }}
              >
                Register
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent className="bg-white p-4 rounded-md">
            <MDBTabsPane show={justifyActive === "tab1"}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                action=""
              >
                <div>
                  <p className="text-gray-600 pb-1 font-semibold">
                    Email Address:
                  </p>
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Email"
                    id="form1"
                    type="email"
                    placeholder="Enter a valid email address"
                    value={givenEmail}
                    onChange={(e) => setGivenEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <p className="text-gray-600 pb-1 font-semibold">Password:</p>
                  <div className="flex items-center justify-between relative">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Password"
                      id="form2"
                      type={showPassword.login_pass ? "text" : "password"}
                      placeholder="Enter your password"
                      value={givenPassword}
                      onChange={(e) => setGivenPassword(e.target.value)}
                      required
                    />
                    <span
                      className="absolute right-3 bottom-1/2 cursor-pointer group"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          login_pass: !showPassword.login_pass,
                        })
                      }
                    >
                      {showPassword.login_pass ? (
                        <i class="fa-regular fa-eye group-hover:text-primary-dark"></i>
                      ) : (
                        <i class="fa-regular fa-eye-slash group-hover:text-primary-dark"></i>
                      )}
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-4 ">
                  <Link className="border-b border-primary" to="/passwordreset">
                    Forgot password?{" "}
                  </Link>
                </div>
                <button className="mb-4 w-100" onClick={handleLogin}>
                  {loading ? "Loading..." : "Login"}
                </button>
              </form>
              <p className="text-center">
                Not a member?{" "}
                <span
                  className="cursor-pointer text-primary font-semibold"
                  onClick={() => {
                    setJustifyActive("tab2");
                  }}
                >
                  Register
                </span>
              </p>
            </MDBTabsPane>

            {/* here is the register sections */}

            <MDBTabsPane show={justifyActive === "tab2"}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                action=""
              >
                <MDBInput
                  wrapperClass="mb-4"
                  label="Name"
                  id="form1"
                  type="text"
                  value={givenName}
                  onChange={(e) => setGivenName(e.target.value)}
                  required={true}
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Username"
                  id="form1"
                  type="text"
                  value={givenUsername}
                  onChange={(e) => setGivenUsername(e.target.value)}
                  required={true}
                />
                <MDBInput
                  wrapperClass="mb-4"
                  label="Email"
                  id="form1"
                  type="email"
                  value={givenEmail}
                  onChange={(e) => setGivenEmail(e.target.value)}
                  required={true}
                />
                <div className="relative flex items-center">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Password"
                    id="form1"
                    type={showPassword.password ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                  />
                  <span
                    className="absolute right-3 top-1.5 cursor-pointer group"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password,
                      })
                    }
                  >
                    {showPassword.password ? (
                      <i class="fa-regular fa-eye group-hover:text-primary-dark"></i>
                    ) : (
                      <i class="fa-regular fa-eye-slash group-hover:text-primary-dark"></i>
                    )}
                  </span>
                </div>

                <div className="d-flex justify-content-center mb-4">
                  <MDBCheckbox
                    name="flexCheck"
                    id="flexCheckDefault"
                    label="I have read and agree to the terms"
                  />
                </div>

                <MDBBtn className="mb-4 w-100" onClick={handleRegister}>
                  {loading ? "Loading..." : "Register"}
                </MDBBtn>
              </form>
              <p className="text-center">
                Already a member?
                <span
                  className="cursor-pointer text-primary font-semibold"
                  onClick={() => {
                    setJustifyActive("tab1");
                  }}
                >
                  {" "}
                  Login
                </span>
              </p>
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBContainer>
      </div>
    </div>
  );
}

export default LoginSignup;
