import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import BlogCard from "./BlogCard";

const BlogSection = () => {
  const [blogData, setBlogData] = useState([]);

  // State to store the selected section filter (default: "All")
  const [selectedFilter, setSelectedFilter] = useState("All");

  // State to store the search query
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle section filter selection
  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter);
  };

  // Function to handle search query input
  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  useState(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/blogs/`)
      .then((response) => {
        setBlogData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredBlogs = blogData.filter((blog) => {
    const titleMatches = blog.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const filterMatches =
      selectedFilter === "All" || blog.section === selectedFilter;

    return titleMatches && filterMatches;
  });

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className="container text-center">
        {/* <h2 className="text-3xl font-bold text-center mb-2 text-blue-600">
          Latest Blogs
        </h2> */}
        <p className="text-center text-lg font-semibold mb-4 max-w-[1000px] mx-auto">
        Stay connected with Lifeline Achham:- Where we regularly update about Achham's tourism, history, and various information.
        </p>
        <div className="mb-4" c>
          <input
            type="text"
            placeholder="Search by title..."
            className="px-3 py-2 rounded w-full sm:w-1/2 md:w-1/3"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {filteredBlogs.map((blog) => (
            <Link
              to={`blogDetail/${blog.id}`}
              state={{
                // date: blog.date,
                date: new Date(blog.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }),
                // name: blog.name,
                name: blog.author,
                key: blog.title,
                // imageSrc: blog.imageSrc,
                imageSrc: blog.thumbnail,
                title: blog.title,
                // description: blog.description,
                description: blog.content,
              }}
            >
              <BlogCard
                // date={blog.date}
                date={new Date(blog.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                name={blog.author}
                key={blog.title}
                imageSrc={blog.thumbnail}
                title={blog.title}
                description={blog.content}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
