import React from "react";
import { CiPhone } from "react-icons/ci";
import { TbArrowBigRightLines } from "react-icons/tb";

const TeamCard = ({ data, index }) => {
  return (
    <div
      key={index}
      className="w-full h-full mx-auto bg-white rounded-lg shadow-lg overflow-hidden"
    >
      <img
        className="w-full h-44 object-cover object-top sm:h-48"
        src={data.image}
        alt="Team Member"
      />
      <div className="p-3 border-t">
        <h2 className="text-md font-bold text-gray-800 md:text-xl">
          {data.name}
        </h2>
      </div>
      <div className="border-t p-3 pb-4">
        <p className="text-sm font-semibold text-gray-600 flex gap-2 pb-2">
          <TbArrowBigRightLines className="h-5 w-5 text-primary-dark" />
          <span>{data.position}</span>
        </p>
        <p className="text-sm font-semibold text-gray-600 flex gap-2">
          <CiPhone className="h-5 w-5 text-primary-dark" />
          Contact: <span className="font-bold">{data.contact}</span>
        </p>
      </div>
    </div>
  );
};

export default TeamCard;
