import { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa";

export default function OptionField({ onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const ref = useRef(null);

  const options = [
    {
      name: "Blood Donor: यदि तपाईं अछाम भित्र बस्नुहुन्छ भने !",
      value: "donor",
    },
    {
      name: "Blood Donor: यदि तपाईं अछाम बाहिर बस्नुहुन्छ भने ! ",
      value: "volunteer",
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option.name);
    onChange && onChange(option.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="w-full max-w-md mx-auto relative bg-transparent" ref={ref}>
      <span
        className={`w-full py-2 px-3 border-gray-300 box-border cursor-pointer text-md rounded-md text-left flex items-center justify-between outline-none ${
          selectOption === "" ? "font-semibold" : "text-gray-500"
        }`}
        onClick={toggleDropdown}
        style={{
          outline: isOpen ? "2px solid #007BFF" : "none",
          border: isOpen ? "1px solid white" : "1px solid #d1d5d3",
        }}
      >
        {selectedOption || "Select an option"}
        <FaAngleDown />
      </span>

      {isOpen && (
        <ul className="absolute w-full bg-white font-semibold border border-gray-300 rounded-md mt-1 z-10 overflow-hidden shadow-md">
          <li
            className={`px-4 py-2 cursor-pointer hover:bg-gray-100 text-md border-b ${
              selectedOption === "" && "bg-primary text-white"
            }`}
            style={{ fontSize: "15px" }}
          >
            Select an option
          </li>
          {options.map((option) => (
            <li
              key={option.value}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 border-b last:border-none ${
                option.name === selectedOption && "bg-primary text-white"
              }`}
              onClick={() => selectOption(option)}
              style={{ fontSize: "15px" }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
