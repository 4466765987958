import React, { useEffect } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./components/Layout";

import {
  clearNotification,
  updateNotification,
} from "./features/notification/notificationSlice";
import { getUser } from "./features/user/userSlice";

function App() {
  const dispatch = useDispatch();
  const [cartCookies, setCartCookies] = useCookies(["user"]);
  const { showModal } = useSelector((store) => store.notification);

  useEffect(() => {
    const userCookie = new Cookies();
    if (userCookie.get("access")) {
      // dispatch(getUser(userCookie.get("access")));
      dispatch(getUser(userCookie.get("access")));
    }
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (showModal) {
        const name = "showModal";
        const value = false;

        dispatch(updateNotification({ name, value }));

        setTimeout(() => {
          dispatch(clearNotification());
        }, 1000);
      }
    }, 4000);
  }, [showModal, dispatch]);

  return (
    <>
      <Layout />
    </>
  );
}

export default App;
