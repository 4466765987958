import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import TeamCard from "../TeamCard"; // Ensure this is correctly implemented and imported

import ganeshImage from "../../assets/images/Ganesh-kunwar.jpg";
import mahendraImage from "../../assets/images/mahendra-kunwar.jpg";
import krishnaImage from "../../assets/images/krishna-chand-sodari.jpg";
import mandipImage from "../../assets/images/dr-mandip-pathak.jpg";
import axios from "axios";

const profileData = [
  {
    name: "Ganesh Kunwar",
    position: "Founder / Software Developer",
    number: "9810228669",
    imageSrc: ganeshImage,
  },
  {
    name: "Dr. Krishna Bahadur Sodari",
    position: "MBBS / Achham District Hospital",
    number: "9843757325",
    imageSrc: krishnaImage,
  },
  {
    name: "Mahendra Kunwar",
    position: "Anesthesia Officer / Bayalapata Hospital",
    number: "9848444014",
    imageSrc: mahendraImage,
  },
  {
    name: "Dr. Mandip Pathak",
    position:
      "Medical Surgical Director / Orthopaedic Surgeon / Bayalapata Hospital",
    number: "9851273599",
    imageSrc: mandipImage,
  },
];

// TODO: will update this later once the backend is completed

// const volunteerData = [
//   {
//     name: "Ganesh Kunwar",
//     position: "Volunteer",
//     address: "Sanfebagar",
//     number: "9810228669",
//     imageSrc: ganeshImage,
//   },
//   {
//     name: "Mahendra Kunwar",
//     position: "Volunteer",
//     address: "Sanfebagar, Bayalapata Hospital",
//     number: "9848444014",
//     imageSrc: mahendraImage,
//   },
//   {
//     name: "Mandip Pathak",
//     position: "Volunteer",
//     address: "Mangalsen, Achham District Hospital",
//     number: "9848435249",
//     imageSrc: mandipImage,
//   },
//   {
//     name: "Dr. Krishna Chand Sodari",
//     position: "Volunteer",
//     address: "Mangalsen, Achham District Hospital",
//     number: "9843757325",
//     imageSrc: krishnaImage,
//   },
// ];

// const advisorData = [
//   {
//     name: "Dr. Krishna Chand Sodari",
//     position: "MBBS / Achham District Hospital",
//     number: "9843757325",
//     address: "Mangalsen, Achham District Hospital",
//     imageSrc: krishnaImage,
//   },
//   {
//     name: "Dr. Mandip Pathak",
//     position:
//       "Medical Surgical Director / Orthopaedic Surgeon / Bayalapata Hospital",
//     number: "9851273599",
//     address: "Mangalsen, Achham District Hospital",
//     imageSrc: mandipImage,
//   },
// ];

const TeamSection = () => {
  const { page } = useParams();
  const current_page =
    page === "management-team"
      ? "is_management_team"
      : page === "volunteer"
      ? "is_volunteer"
      : "is_advisor";

  const [data, setData] = React.useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/teamlifelineachham/`, {})
      .then((response) => {
        const teamData = response.data?.filter((item) => item[current_page]);
        setData(teamData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [current_page]);

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className="container">
        <h1 className="text-center text-3xl mb-4 font-bold text-blue-600">
          <span className="border-b-2 px-1 border-blue-600">
            {page === "management-team"
              ? "Management Team"
              : page === "volunteer"
              ? "Lifeline Achham Members"
              : page === "advisor"
              ? "Lifeline Achham Advisors"
              : "Welcome to Our Team"}
          </span>
        </h1>
        {data.length > 0 ? (
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 bg-gray-100 ">
            {data?.map((profile, index) => (
              <TeamCard key={index} data={profile} index={index} />
            ))}
          </div>
        ) : (
          <p className="text-center">No data available.</p>
        )}
      </div>
    </div>
  );
};

export default TeamSection;
