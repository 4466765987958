import React, { useState } from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ date, name, imageSrc, title, description }) => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div className="w-full rounded overflow-hidden shadow-lg">
      <img
        className="w-full h-52 object-cover cursor-pointer transition-transform duration-300 hover:scale-105"
        src={imageSrc}
        alt="Blog post"
      />
      <div className="px-3 py-2">
        <span className="text-blue-700 text-base font-bold   ">
          {name} || {date}
        </span>
      </div>
      <hr />
      <div className="px-3 py-3">
        <div className="font-bold text-xl mb-2">{title}</div>
        {/* Show the description based on showContent state */}
        <p className="text-gray-700 text-base overflow-hidden line-clamp-3">
          {description}
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
