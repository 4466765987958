import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, redirect, useNavigate } from "react-router-dom";
import { updateNotification } from "../../features/notification/notificationSlice";
import OptionField from "../OptionField";

// const municipalityWardOptions = [
//   "Ward 1",
//   "Ward 2",
//   "Ward 3",
//   "Ward 4",
//   "Ward 5",
//   "Ward 6",
// ];
// const vdcOptions = ["Vdcs A", "Vdcs B", "Vdcs C"];
// const wardOptions = [
//   "Ward 1",
//   "Ward 2",
//   "Ward 3",
//   "Ward 4",
//   "Ward 5",
//   "Ward 6",
// ];

const bloodgroupOptions = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const ApplyForm = ({ isLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [municipalityOptions, setMunicipalityOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [applyFor, setApplyFor] = useState("");
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    ward: "",
    tole: "",
    profession: "",
    workplace: "",
    bloodGroup: "",
    province: "",
    district: "",
    municipality: "",
    current_address: "",
    image: null,
    age: "",
    contact: "",
    latest_received: "",
    latest_donation: "",
    weight_kg: "",
    gender: "",
    is_volunteer: false,
    is_donor: false,
    has_accepted_terms: false,
  });

  const [errors, setErrors] = useState({});

  const handleToggleTermsAndConditions = () => {
    setShowTermsAndConditions(!showTermsAndConditions);
  };
  const handleOptionChange = (value) => {
    setApplyFor(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  useEffect(() => {
    if (showTermsAndConditions) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showTermsAndConditions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, perform form submission logic here
      console.log("Form data submitted:", formData);

      // formData.append('image', selectedImage);
      formData.image = selectedImage;
      formData.is_volunteer = applyFor === "volunteer" ? true : false;
      formData.is_donor = applyFor === "donor" ? true : false;

      if (formData.has_accepted_terms === false) {
        return;
      }

      setLoading(true);

      const fullUrl = `${process.env.REACT_APP_API_URL}/store/donor-requests/`;

      const response = await axios
        .post(
          fullUrl,
          {
            ...formData,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .catch((e) => {
          setLoading(false);
          const errors = e.response.data;
          let firstMessage = "An error occurred";
          if (errors && typeof errors === "object") {
            const firstKey = Object.keys(errors)[0];
            if (firstKey && Array.isArray(errors[firstKey])) {
              firstMessage = `${
                firstKey.charAt(0).toUpperCase() + firstKey.slice(1)
              }: ${errors[firstKey][0]}`;
            }
          }
          console.log("error", e.response.data);

          console.log("firstMessage", firstMessage);

          let name = "message";
          let value = firstMessage;
          dispatch(updateNotification({ name, value }));
          name = "showModal";
          value = true;
          dispatch(updateNotification({ name, value }));
          setLoading(false);
          return;
        })
        .finally(() => {
          setLoading(false);
        });

      if (response === undefined) return;

      if (response.status === 201) {
        let name = "message";
        let value = "Your request has been submitted successfully";
        dispatch(updateNotification({ name, value }));
        name = "showModal";
        value = true;
        dispatch(updateNotification({ name, value }));
        navigate("/");
      }

      // Reset the form after successful submission

      setFormData({
        name: "",
        phoneNumber: "",
        ward: "",
        tole: "",
        temporaryaddress: "",
        profession: "",
        workplace: "",
        bloodGroup: "",
        province: "",
        district: "",
        municipality: "",
        vdc: "",
        current_address: "",
        image: null,
        age: "",
        contact: "",
        latest_received: "",
        latest_donation: "",
        weight_kg: "",
        gender: "",
        is_volunteer: false,
        is_donor: false,
        has_accepted_terms: false,
      });
      setErrors({});
    } else {
      // Set the validation errors to display to the user
      console.log("error");
      setErrors(validationErrors);
    }
  };

  const handleProvinceChange = (e) => {
    const selectedProvinceId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      province: selectedProvinceId,
    }));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/store/districts/?province=${selectedProvinceId}`
      )
      .then((response) => {
        setDistrictOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDistrictChange = (e) => {
    const selectedDistrictId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      district: selectedDistrictId,
    }));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/store/municipalities/?district=${selectedDistrictId}`
      )
      .then((response) => {
        setMunicipalityOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMunicipalityChange = (e) => {
    const selectedMunicipalityId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      municipality: selectedMunicipalityId,
    }));

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/store/wards/?municipality=${selectedMunicipalityId}`
      )
      .then((response) => {
        setWardOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWardChange = (e) => {
    const selectedWardId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ward: selectedWardId,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (!/^[A-Za-z][A-Za-z\s]*$/.test(formData.name)) {
      errors.name =
        "Name must start with alphabets and can only contain alphabets and spaces.";
    }

    if (!formData.contact.match(/^[0-9]{10}$/)) {
      errors.phoneNumber =
        "Invalid phone number format. Please enter a 10-digit number.";
    }

    // if (formData.latest_donation) {
    //   const latestDonationDate = new Date(formData.latest_donation)
    //     .toISOString()
    //     .split("T")[0];
    //   const daysSinceLastDonation = Math.floor(
    //     (Date.now() - new Date(latestDonationDate).getTime()) /
    //       (1000 * 60 * 60 * 24)
    //   );
    //   if (daysSinceLastDonation < 60) {
    //     errors.latest_donation =
    //       "You must wait at least 60 days between donations.";
    //   }
    // }
    // if (formData.weight_kg < 45) {
    //   errors.weight_kg = "Weight must be more than 45 kg";
    // }
    if (formData.age < 18) {
      errors.age = "Age must be more than 18";
    }

    if (formData.age > 60) {
      errors.age = "Age must be less than 60";
    }

    if (applyFor === "") {
      errors.applyFor = "Please select an option";
    }
    // Add more validations for other required fields as needed

    return errors;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/provinces/`)
      .then((response) => {
        setProvinceOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className=" bg-white rounded-lg max-w-2xl mx-auto p-4">
        <h1 className="text-center text-xl font-extrabold mb-4">
          <span className="border-b-2 border-primary px-1 pb-1">
            Lifeline Achham :-Blood Donor Form
          </span>
        </h1>
        <p className="text-center">
          कृपया lifelineachham मा रक्तदाता बन्नका लागि फारम भर्नुहोस्। धन्यवाद!
        </p>
        <form
          onSubmit={handleSubmit}
          className="w-full mx-auto py-4 rounded-md bg-white"
        >
          {/* <h3 className="text-2xl font-bold mb-2">Volunteer Form:</h3> */}
          <div className="w-full grid grid-cols-2 gap-3">
            <div>
              <label htmlFor="name" className="block font-bold">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Please Enter Your Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
            </div>
            <div>
              <label htmlFor="name" className="block font-bold">
                Gender:
              </label>
              <select
                name="gender"
                id="gender"
                required
                value={formData.gender}
                onChange={handleChange}
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              {errors.gender && <p className="text-red-500">{errors.gender}</p>}
            </div>
            <div>
              <label htmlFor="age" className="block font-bold">
                Age:
              </label>
              <input
                type="number"
                id="age"
                name="age"
                placeholder="Enter Your Age"
                value={formData.age}
                onChange={handleChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
              {errors.age && <p className="text-red-500">{errors.age}</p>}
            </div>
            <div>
              <label htmlFor="weight_kg" className="block font-bold">
                Weight (kg):
              </label>
              <input
                type="number"
                id="weight_kg"
                name="weight_kg"
                placeholder="Enter Your Weight"
                value={formData.weight_kg}
                onChange={handleChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
              {errors.weight_kg && (
                <p className="text-red-500">{errors.weight_kg}</p>
              )}
            </div>
            <div>
              <label htmlFor="contact" className="block font-bold">
                Phone No :
              </label>
              <input
                type="tel"
                id="contact"
                name="contact"
                placeholder="Enter Your Phone number"
                value={formData.contact}
                onChange={handleChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
              {errors.phoneNumber && (
                <p className="text-red-500">{errors.phoneNumber}</p>
              )}
            </div>
            <div className="">
              <label htmlFor="tole" className="block font-bold">
                Tole/village
              </label>
              <input
                type="text"
                id="tole"
                name="tole"
                placeholder="Enter your Tole/Village"
                value={formData.tole}
                onChange={handleChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
            </div>
            <div>
              <label htmlFor="bloodGroup" className="block font-bold">
                BloodGroup :
              </label>
              <select
                id="bloodGroup"
                name="bloodGroup"
                value={formData.bloodGroup}
                onChange={handleChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              >
                <option value=""> Select BloodGroup </option>
                {bloodgroupOptions.map((bloodGroup, idx) => (
                  <option key={idx} value={bloodGroup}>
                    {bloodGroup}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="profession" className="block font-bold">
                Profession
              </label>
              <input
                type="text"
                id="profession"
                name="profession"
                placeholder="Enter your  profession"
                value={formData.profession}
                onChange={handleChange}
                // required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
            </div>
            <div>
              <label htmlFor="workplace" className="block font-bold">
                Workplace
              </label>
              <input
                type="text"
                id="workplace"
                name="workplace"
                placeholder="Enter your  workplace"
                value={formData.workplace}
                onChange={handleChange}
                // required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
            </div>

            <div>
              <label htmlFor="province" className="block font-bold">
                Province:
              </label>
              <select
                id="province"
                name="province"
                value={formData.province}
                onChange={handleProvinceChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              >
                <option value="">Select Province </option>
                {provinceOptions.map((province) => (
                  <option key={province.id} value={province.id}>
                    {province.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="district" className="block font-bold">
                District:
              </label>
              <select
                id="district"
                name="district"
                value={formData.district}
                onChange={handleDistrictChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              >
                <option value=""> Select District </option>
                {districtOptions.map((district) => (
                  <option key={district.id} value={district.id}>
                    {district.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="municipality" className="block font-bold">
                Municipality/Vdc:
              </label>
              <select
                id="municipality"
                name="municipality"
                value={formData.municipality}
                onChange={handleMunicipalityChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              >
                <option value="">Select Municipality/vdcs</option>
                {municipalityOptions.map((municipality) => (
                  <option key={municipality.id} value={municipality.id}>
                    {municipality.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="ward" className="block font-bold">
                Ward:
              </label>
              <select
                id="ward"
                name="ward"
                value={formData.ward}
                onChange={handleWardChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              >
                <option value="">Select Ward</option>
                {wardOptions.map((ward) => (
                  <option key={ward.id} value={ward.id}>
                    {ward.name
                      ? `${ward.name} (${ward.ward_number})`
                      : ward.ward_number}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-span-2">
              <label htmlFor="current_address" className="block font-bold">
                Current Address
              </label>
              <input
                type="text"
                id="current_address"
                name="current_address"
                placeholder="Enter your Temporary Address"
                value={formData.current_address}
                onChange={handleChange}
                required
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
            </div>
            <div>
              <label htmlFor="image" className="block font-bold">
                Image:
              </label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                // onChange={handleChange}
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
                //required  -> not compulsary
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />
            </div>
            <div>
              <label htmlFor="latest_donation" className="block font-bold">
                Last Blood Donation Date(within 3 months)
              </label>
              <input
                type="date"
                id="latest_donation"
                name="latest_donation"
                onChange={handleChange}
               // required    // this field is not compulsory   
                className="w-full mt-1 px-3 py-2 border rounded-lg focus:outline-primary-light"
              />

              {errors.latest_donation && (
                <p className="text-red-500">{errors.latest_donation}</p>
              )}
            </div>
            <div className="col-span-2">
              <label htmlFor="applyFor" className="block font-bold mb-1">
                Apply as:
              </label>
              <OptionField onChange={handleOptionChange} />
              {errors.applyFor && (
                <p className="text-red-500">{errors.applyFor}</p>
              )}
            </div>
            <div className="col-span-2">
              <label className="block font-bold">
                Accept Terms and Conditions
              </label>
              <div className="mt-1 flex items-center gap-2">
                <input
                  type="checkbox"
                  id="accept_yes"
                  name="has_accepted_terms"
                  required
                  checked={formData.has_accepted_terms}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      has_accepted_terms: e.target.checked,
                    })
                  }
                  className="h-4 w-4 border rounded-lg focus:outline-primary-light"
                />
                <label className="flex items-center gap-2 font-semibold">
                  Yes, I agree to the{" "}
                  <span
                    className="underline cursor-pointer text-blue-500 hover:text-blue-600 underline-offset-2 hover:underline"
                    onClick={handleToggleTermsAndConditions}
                  >
                    Terms and Conditions.
                  </span>
                </label>
                {showTermsAndConditions && (
                  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white px-4 rounded mx-4 my-4 w-full relative max-h-[90vh] overflow-auto md:max-w-2xl lg:max-w-3xl">
                      <div className="flex justify-between items-center pb-2 pt-4 border-b-2 sticky top-0 bg-white">
                        <h2>Terms and Conditions</h2>
                        <i
                          class="fa-solid fa-xmark cursor-pointer text-2xl text-gray-600"
                          onClick={handleToggleTermsAndConditions}
                        ></i>
                      </div>
                      <div className="mx-auto max-w-4xl py-4">
                        <h2 className="font-bold text-lg">1. Introduction</h2>
                        <p className="mt-2">
                          Welcome to <strong>Lifeline Achham</strong>. By
                          accessing or using this web application, you agree to
                          be bound by these Terms and Conditions. The purpose of
                          Lifeline Achham is to provide a platform that connects
                          individuals in need of blood with active blood donors
                          within the Achham community. This platform is
                          currently utilized primarily by Nyaya Health and
                          District Hospital, and the people of Achham serve as
                          the donors. These Terms outline the responsibilities
                          of donors, users, and the platform's operators.
                        </p>

                        <h2 className="mt-4 font-bold text-lg">
                          2. Eligibility
                        </h2>
                        <ul className="list-disc list-inside mt-2">
                          <li>
                            In good health and fit to donate blood as per the
                            medical guidelines applicable in Nepal.
                          </li>
                          <li>
                            Over the age of 18 and legally capable of entering
                            into a binding agreement.
                          </li>
                          <li>
                            Aware that blood donation carries certain risks, and
                            you voluntarily assume all associated risks.
                          </li>
                        </ul>

                        <h2 className="mt-4 font-bold text-lg">
                          3. Donor Responsibilities
                        </h2>
                        <ul className="list-disc list-inside mt-2">
                          <li>
                            You are voluntarily participating in the program and
                            may withdraw from it at any time by providing notice
                            to the platform administrators.
                          </li>
                          <li>
                            You are responsible for providing accurate, current,
                            and complete information, including your health
                            status, contact details, and any other relevant data
                            required for the purposes of blood donation.
                          </li>
                          <li>
                            You agree to undergo any necessary medical
                            screenings or checks prior to donating blood, in
                            compliance with local health regulations.
                          </li>
                          <li>
                            You understand that blood donation is a voluntary
                            service and that no compensation, either monetary or
                            otherwise, will be provided for your participation.
                          </li>
                        </ul>

                        <h2 className="mt-4 font-bold text-lg">
                          4. Use of Information
                        </h2>
                        <p className="mt-2">
                          The information you provide through Lifeline Achham
                          will be used solely for the purposes of connecting
                          individuals in need of blood with donors. Your
                          personal information, including your contact details,
                          will be shared only with authorized medical personnel,
                          health organizations, or individuals requiring blood
                          transfusions in emergencies.
                        </p>

                        <h2 className="mt-4 font-bold text-lg">
                          5. Limitation of Liability
                        </h2>
                        <p className="mt-2">
                          Lifeline Achham, its operators, Nyaya Health and
                          District Hospital, and any affiliated organizations do
                          not assume liability for any adverse outcomes
                          resulting from your participation as a blood donor,
                          including but not limited to health complications or
                          any related medical issues. You acknowledge that blood
                          donation involves inherent risks, and you voluntarily
                          assume those risks by choosing to participate.
                        </p>

                        <h2 className="mt-4 font-bold text-lg">
                          6. Termination
                        </h2>
                        <p className="mt-2">
                          Lifeline Achham reserves the right to terminate or
                          suspend access to the platform at any time without
                          notice if it is determined that a user or donor has
                          violated these Terms or engaged in any conduct deemed
                          harmful or inappropriate.
                        </p>

                        <h2 className="mt-4 font-bold text-lg">
                          7. Amendments
                        </h2>
                        <p className="mt-2">
                          These Terms and Conditions may be amended from time to
                          time at the sole discretion of Lifeline Achham. Users
                          and donors will be notified of any significant changes
                          to the Terms, and continued use of the platform after
                          any such changes constitutes acceptance of the revised
                          Terms.
                        </p>

                        <h2 className="mt-4 font-bold text-lg">
                          8. Governing Law
                        </h2>
                        <p className="mt-2">
                          These Terms and Conditions shall be governed by and
                          construed in accordance with the laws of Nepal. Any
                          disputes arising out of or related to these Terms will
                          be subject to the exclusive jurisdiction of the courts
                          of Nepal.
                        </p>

                        <h2 className="mt-4 font-bold text-lg">
                          9. Contact Information
                        </h2>
                        <p className="mt-2">
                          If you have any questions regarding these Terms and
                          Conditions, please contact us
                        </p>
                      </div>
                      <button
                        type="button"
                        className="rounded-md mb-4 text-white bg-primary-dark px-4"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            has_accepted_terms: true,
                          });
                          setShowTermsAndConditions(false);
                        }}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button className="w-[150px] px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplyForm;
