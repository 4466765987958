import { MDBBtn, MDBCheckbox, MDBInput, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, registerUser } from "../features/user/userSlice";
import { updateNotification } from "../features/notification/notificationSlice";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();

  const { first_name } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [givenEmail, setGivenEmail] = useState("");
  const [givenPassword, setGivenPassword] = useState("");
  const [givenUsername, setGivenUsername] = useState("");
  const [givenName, setGivenName] = useState("");

  const handleRegister = async () => {
    const name = givenName.split(" ");
    if (
      givenEmail === "" ||
      givenPassword === "" ||
      givenUsername === "" ||
      name[0] === "" ||
      name[1] === ""
    ) {
      return;
    }

    try {
      setLoading(true);
      const result = await dispatch(
        registerUser({
          username: givenUsername,
          email: givenEmail,
          password: givenPassword,
          first_name: name[0],
          last_name: name[1],
        })
      );

      if (result.payload?.success) {
        const actionResult = await dispatch(
          loginUser({ email: givenEmail, password: givenPassword })
        );
        if (actionResult) {
          dispatch(
            updateNotification({
              name: "message",
              value: "Registered successfully, Please fill the form",
            })
          );
          dispatch(
            updateNotification({
              name: "showModal",
              value: true,
            })
          );
        }
        setGivenPassword("");
        setGivenUsername("");
        setGivenName("");

        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred during registration:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (first_name) {
      navigate("/");
    }
  }, [first_name]);

  return (
    <div className="bg-gray-100 py-8 sm:py-12 md:py-16">
      <div className=" bg-white rounded-lg max-w-2xl mx-auto p-4">
        <h1 className="text-center text-xl font-extrabold mb-4">
          <span className="border-b-2 border-primary px-1 pb-1">
            Register User
          </span>
        </h1>
        <MDBTabsPane show>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            action=""
          >
            <MDBInput
              wrapperClass="mb-4"
              label="Name"
              id="form1"
              type="text"
              value={givenName}
              onChange={(e) => setGivenName(e.target.value)}
              required={true}
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Username"
              id="form1"
              type="text"
              value={givenUsername}
              onChange={(e) => setGivenUsername(e.target.value)}
              required={true}
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Email"
              id="form1"
              type="email"
              value={givenEmail}
              onChange={(e) => setGivenEmail(e.target.value)}
              required={true}
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="form1"
              type="password"
              value={givenPassword}
              onChange={(e) => setGivenPassword(e.target.value)}
              required={true}
            />

            <div className="d-flex justify-content-center mb-4">
              <MDBCheckbox
                name="flexCheck"
                id="flexCheckDefault"
                label="I have read and agree to the terms"
              />
            </div>

            <div className="text-center">
              <MDBBtn className="mb-4 w-100 max-w-sm" onClick={handleRegister}>
                {loading ? "Registering..." : "Next"}
              </MDBBtn>
            </div>
          </form>
        </MDBTabsPane>
      </div>
    </div>
  );
};

export default Register;
