import axios from "axios";
import React, { useEffect, useState } from "react";
import Pagination from "./Pagination";

function RecentDoner() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [bloodGroupSearchQuery, setBloodGroupSearchQuery] = useState("");

  const itemsPerPage = 50;
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleBloodGroupSearchChange = (e) => {
    setBloodGroupSearchQuery(e.target.value);
  };
  const isRecent = (latestDonation) => {
    const donationDate = new Date(latestDonation);
    const today = new Date();
    const differenceInDays =
      (today.getTime() - donationDate.getTime()) / (1000 * 60 * 60 * 24);

    return differenceInDays < 90;
  };

  useEffect(() => {
    const filteredItems = data.filter((item) => {
      const name = item.name;

      // if(data.length >0){
      // const nameMatch = item.name
      const nameMatch = name.toLowerCase().includes(searchQuery.toLowerCase());
      const bloodGroupMatch = item.blood_group
        .toLowerCase()
        .includes(bloodGroupSearchQuery.toLowerCase());

      if (nameMatch && bloodGroupMatch) {
        setIsFiltered(true);
      }
      if (!nameMatch && !bloodGroupMatch) {
        setIsFiltered(false);
      }
      return nameMatch && bloodGroupMatch;
      // }
    });
    setFilteredData(filteredItems);
  }, [searchQuery, bloodGroupSearchQuery]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/store/lifeline-blood-donors/`)
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="px-4 py-8 md:px-8 mt-2">
      <h2 className="text-3xl font-bold mt-2 mb-4 text-center">
        LIST OF HEROES
      </h2>
      <div className="overflow-x-auto">
        <div className="mb-4">
          {/* Search Input */}
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by name..."
            className="w-30 px-4 py-2 border rounded-lg"
          />
          {/* Blood Group Search Input */}
          <input
            type="text"
            value={bloodGroupSearchQuery}
            onChange={handleBloodGroupSearchChange}
            placeholder="Search by blood group..."
            className="w-30 ml-4 px-4 py-2 border rounded-lg"
          />
        </div>
        <table className="w-full border-collapse text-center">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="py-2">S.N</th>
              <th className="py-2">Name</th>
              <th className="py-2">Contact Number</th>
              <th className="py-2">Blood Group</th>
              <th className="py-2">Hospital</th>
              <th className="py-2">Last Blood Donation Date</th>
              <th className="py-2">Total Donation</th>
              <th className="py-2">T-shirt Received</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 && (
              <tr className="h-16">
                <td colSpan="9">No data found.</td>
              </tr>
            )}
            {filteredData.map((item, idx) => (
              <tr
                key={item.id}
                className={`${
                  isRecent(item.last_donation_date) ? "text-red-400" : ""
                }`}
              >
                <td className="py-2">{idx + 1}</td>
                {item.name ? (
                  <td className="py-2">{item.name}</td>
                ) : (
                  <td className="py-2">
                    {item.first_name + " " + item.last_name}
                  </td>
                )}
                <td className="py-2">{item.contact}</td>
                <td className="py-2">{item.blood_group}</td>
                <td className="py-2">{item.hospital}</td>
                <td className="py-2">{item.last_donation_date}</td>
                <td className="py-2">{item.total_donations}</td>
                <td className="py-2">{item.received_tshirt ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <Pagination
          data={isFiltered ? filteredData : data}
          setData={setFilteredData}
          pageSize={itemsPerPage}
        />
      </div>
    </div>
  );
}

export default RecentDoner;
