import React from "react";

const TermsAndCondition = () => {
  return (
    <>
      <h1 className="text-center mt-4 font-extrabold text-3xl">
        Terms and Conditions
      </h1>
      <div className="mx-auto max-w-4xl px-4 py-6">
        <p className="text-sm font-semibold">
          Effective Date: [15 August 2024]
        </p>

        <h2 className="mt-4 font-bold text-lg">1. Introduction</h2>
        <p className="mt-2">
          Welcome to <strong>Lifeline Achham</strong>. By accessing or using
          this web application, you agree to be bound by these Terms and
          Conditions. The purpose of Lifeline Achham is to provide a platform
          that connects individuals in need of blood with active blood donors
          within the Achham community. This platform is currently utilized
          primarily by Nyaya Health and District Hospital, and the people of
          Achham serve as the donors. These Terms outline the responsibilities
          of donors, users, and the platform's operators.
        </p>

        <h2 className="mt-4 font-bold text-lg">2. Eligibility</h2>
        <ul className="list-disc list-inside mt-2">
          <li>
            In good health and fit to donate blood as per the medical guidelines
            applicable in Nepal.
          </li>
          <li>
            Over the age of 18 and legally capable of entering into a binding
            agreement.
          </li>
          <li>
            Aware that blood donation carries certain risks, and you voluntarily
            assume all associated risks.
          </li>
        </ul>

        <h2 className="mt-4 font-bold text-lg">3. Donor Responsibilities</h2>
        <ul className="list-disc list-inside mt-2">
          <li>
            You are voluntarily participating in the program and may withdraw
            from it at any time by providing notice to the platform
            administrators.
          </li>
          <li>
            You are responsible for providing accurate, current, and complete
            information, including your health status, contact details, and any
            other relevant data required for the purposes of blood donation.
          </li>
          <li>
            You agree to undergo any necessary medical screenings or checks
            prior to donating blood, in compliance with local health
            regulations.
          </li>
          <li>
            You understand that blood donation is a voluntary service and that
            no compensation, either monetary or otherwise, will be provided for
            your participation.
          </li>
        </ul>

        <h2 className="mt-4 font-bold text-lg">4. Use of Information</h2>
        <p className="mt-2">
          The information you provide through Lifeline Achham will be used
          solely for the purposes of connecting individuals in need of blood
          with donors. Your personal information, including your contact
          details, will be shared only with authorized medical personnel, health
          organizations, or individuals requiring blood transfusions in
          emergencies.
        </p>

        <h2 className="mt-4 font-bold text-lg">5. Limitation of Liability</h2>
        <p className="mt-2">
          Lifeline Achham, its operators, Nyaya Health and District Hospital,
          and any affiliated organizations do not assume liability for any
          adverse outcomes resulting from your participation as a blood donor,
          including but not limited to health complications or any related
          medical issues. You acknowledge that blood donation involves inherent
          risks, and you voluntarily assume those risks by choosing to
          participate.
        </p>

        <h2 className="mt-4 font-bold text-lg">6. Termination</h2>
        <p className="mt-2">
          Lifeline Achham reserves the right to terminate or suspend access to
          the platform at any time without notice if it is determined that a
          user or donor has violated these Terms or engaged in any conduct
          deemed harmful or inappropriate.
        </p>

        <h2 className="mt-4 font-bold text-lg">7. Amendments</h2>
        <p className="mt-2">
          These Terms and Conditions may be amended from time to time at the
          sole discretion of Lifeline Achham. Users and donors will be notified
          of any significant changes to the Terms, and continued use of the
          platform after any such changes constitutes acceptance of the revised
          Terms.
        </p>

        <h2 className="mt-4 font-bold text-lg">8. Governing Law</h2>
        <p className="mt-2">
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of Nepal. Any disputes arising out of or
          related to these Terms will be subject to the exclusive jurisdiction
          of the courts of Nepal.
        </p>

        <h2 className="mt-4 font-bold text-lg">9. Contact Information</h2>
        <p className="mt-2">
          If you have any questions regarding these Terms and Conditions, please
          contact us
        </p>
      </div>
    </>
  );
};

export default TermsAndCondition;
