import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/LifelineLogo.png";
const Footer = () => {
  //update date dynamically
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-8 bg-primary-bg text-gray-200">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
          <div className="mb-3">
            {/* <h2 className="text-2xl pb.4">Lifeline Achham</h2> */}
            <Link to="/">
              <img src={logo} alt="Logo of lifeline Achham" />
            </Link>
            <p className="text- mt-2">
              Achham,Nepal <br />
              {/* Nyaya Health,District Hospital */}
              {/* <br /> */}
              {/* <strong>Phone Number:</strong><br/> */}
              Nyaya Health:Mahendra kunwar: 9848444014 <br></br>Achham District
              Hospital: Dr Krishna Bahadur Sodari:9843757325  
              <br></br>Arbin Kunwar: 9742387566
              <br />
              {/* <strong>Email:</strong> lifelineachham2080@gmail.com */}
            </p>
          </div>
          <div className="mb-5">
            <h2 className="pb-3">Quick Links</h2>
            <ul className="text-white">
              <li className="pb-3  ">
                <Link to="/">Home</Link>
              </li>

              <li className="pb-3">
                <Link to="/about">About Us</Link>
              </li>
              <li className="pb-3">
                <Link to="/futureproject">Our Projects</Link>
              </li>
              <li className="pb-3">
                <Link to="/contact">Contact us</Link>
              </li>
              <li className="pb-3">
                <Link to="/apply-achhamblooddonor">Donate Now!</Link>
              </li>
            </ul>
          </div>
          <div className="mb-5">
            <h2 className="pb-3">Policy</h2>
            <ul className="text-white">
              <li className="pb-3">
                <Link to="/termsandcondition">Terms and Services</Link>
              </li>
              <li className="pb-3">
                <Link to="/privacyandpolicy">Privacy Policy</Link>
              </li>
              <li className="pb-3">
                <Link to="/faq">FAQ</Link>
              </li>
              <li className="pb-3">
                <a href="#">Suggestion</a>
              </li>
            </ul>
          </div>

          <div className="mb-5">
            <h2 className="pb-4">Join Ours NewsLetter </h2>
            <p className="text-white pb-2">
              Join us please other and never miss out on new tips
            </p>
            <form className="flex flex-row flex-wrap">
              <input
                type="text"
                name=""
                id=""
                className="text-black w-2/3 p-2"
                placeholder="email@gmail.com"
              />
              <button className="p-2 w-1/3 text-white ">Subscribe</button>
            </form>
          </div>
        </div>
      </div>

      <div className=" w-full bg-gray ">
        <div>
          <div className="text-center">
            Copyright :
            <strong>
              <span>Lifeline Achham</span>. All Right Reserved {currentYear}
            </strong>
          </div>
        </div>
        <div className="text-center">
          Designed and Developed By:
          <a href="#" className="text-yellow-500">
            Ganesh Kunwar
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
