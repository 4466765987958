import React, { useState } from "react";
import { FaBars, FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { MdArrowDropDown, MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { is_staff } = useSelector((store) => store.user);
  const [nav, setNav] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="bg-primary-bg">
      <div className="container">
        <div className="w-full flex justify-between items-center z-90 text-white">
          {/* Desktop Menu */}
          <ul className="hidden sm:flex gap-4">
            <li className="py-3 group">
              <Link className="group-hover:text-primary-light" to="/">
                Home
              </Link>
            </li>
            <li className="py-3 relative group">
              <Link className="group-hover:text-primary-light" to="/about">
                About Us
                <MdArrowDropDown className="inline ml-1" />
              </Link>
              <ul className="hidden absolute top-full -left-4 w-max bg-white group-hover:block duration-200">
                <li className="border-b">
                  <Link
                    to="/about/management-team"
                    className="block px-3 py-2 text-gray-800 font-bold hover:text-primar-light"
                  >
                    Management Team
                  </Link>
                </li>
                <li className="border-b">
                  <Link
                    to="/about/volunteer"
                    className="block px-3 py-2 text-gray-800 font-bold hover:text-primar-light"
                  >
                    Lifeline Members
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about/advisor"
                    className="block px-3 py-2 text-gray-800 font-bold hover:text-primar-light"
                  >
                    Lifeline Advisors
                  </Link>
                </li>
              </ul>
            </li>
            {is_staff && (
              <li className="py-3 group">
                <Link
                  className="group-hover:text-primary-light"
                  to="/blooddoner"
                >
                  Blood Donors
                </Link>
              </li>
            )}
            <li className="py-3 group">
              <Link className="group-hover:text-primary-light" to="/blogs">
                Blogs
              </Link>
            </li>
            <li className="py-3 group">
              <Link className="group-hover:text-primary-light" to="/gallery">
                Gallery
              </Link>
            </li>
            <li className="py-3 group">
              <Link className="group-hover:text-primary-light" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>

          {/* Social Media Icons */}
          <div className="flex justify-between gap-4">
            <Link
              to=""
              target="_blank"
              className="py-3 hover:text-primary-light"
            >
              {" "}
              <FaFacebookF className="" />
            </Link>

            <Link
              to=""
              target="_blank"
              className="py-3 hover:text-primary-light"
            >
              {" "}
              <FaTwitter className="" />
            </Link>

            <Link
              to=""
              target="_blank"
              className="py-3 hover:text-primary-light"
            >
              {" "}
              <FaInstagram className="" />
            </Link>
          </div>

          {/* Hamburger icons here */}
          <div onClick={handleNav} className="sm:hidden z-100000">
            <FaBars size={20} className="mr-4 cursor-pointer" />
          </div>

          {/* Mobile Menu */}
          <div
            className={`overflow-y-hidden md:hidden ease-in duration-300 fixed text-gray-300 top-0 w-full h-screen bg-slate-950 px-4 py-7 flex-column z-40 ${
              nav ? "left-0" : "left-[-100%]"
            }
        `}
          >
            <span>
              <MdClose
                size={24}
                className="absolute top-4 right-4 cursor-pointer"
                onClick={() => setNav(!nav)}
              />
            </span>
            <ul className="w-full h-full text-center">
              <li className="text-2xl py-8">
                <Link to="/" onClick={() => setNav(!nav)}>
                  Home
                </Link>
              </li>
              <li className="text-2xl relative">
                <div className="block py-8 cursor-pointer">
                  <span onClick={() => setShowDropdown(!showDropdown)}>
                    About <MdArrowDropDown className="inline ml-1" />{" "}
                  </span>
                  {showDropdown && (
                    <ul
                      className="absolute top-[70%] left-0 right-0 bg-white group-hover:block duration-200"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      <li className="border-b">
                        <Link
                          to="/about/management-team"
                          className="block text-lg px-3 py-3 text-gray-800 font-bold hover:text-primar-light"
                          onClick={handleNav}
                        >
                          Management Team
                        </Link>
                      </li>
                      <li className="border-b">
                        <Link
                          to="/about/volunteer"
                          className="block text-lg px-3 py-3 text-gray-800 font-bold hover:text-primar-light"
                          onClick={handleNav}
                        >
                          Lifeline Volunteer
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/advisor"
                          className="block text-lg px-3 py-3 text-gray-800 font-bold hover:text-primar-light"
                          onClick={handleNav}
                        >
                          Lifeline Advisor
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about"
                          className="block text-lg px-3 py-3 text-gray-800 font-bold hover:text-primar-light"
                          onClick={handleNav}
                        >
                          About Us
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
              {is_staff && (
                <li className="text-2xl py-8">
                  <Link
                    to="/blooddoner"
                    className="text-green-600 hover:text-green-800"
                    onClick={handleNav}
                  >
                    Blood Donors
                  </Link>
                </li>
              )}
              <li className="text-2xl py-8">
                <Link to="/blogs" onClick={handleNav}>
                  Blogs
                </Link>
              </li>

              <li className="text-2xl py-8">
                <Link to="/gallery" onClick={handleNav}>
                  Gallery
                </Link>
              </li>
              <li className="text-2xl py-8">
                <Link to="/contact" onClick={handleNav}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
