import React from "react";
import GallerySection from "../components/ImageGallerySection/GallerySection";
const Gallery = () => {
  return (
    <div className=" ">
      <GallerySection />
    </div>
  );
};

export default Gallery;
